import React, { useContext } from 'react';
import { PaginaContext } from '../App'; // Importamos el contexto
import Accesibilidad from './Accesibilidad';

function Header() {
    const { pagina, setPagina } = useContext(PaginaContext);

    return (
        
        <header className="fondo-verde texto-blanco texto-arial">
            <a href="index.html">
                <img className="logo-header" src="images/logo1.png" />
            </a>
            <nav id="menu">
                <ul>
                    <li className={pagina == 'inicio' ? 'fondo-naranja':''}><a onClick={() => setPagina('inicio')} className="texto-blanco texto-arial">Inicio</a></li>
                    <li className={pagina == 'prevencion' ? 'fondo-naranja':''}><a onClick={() => setPagina('prevencion')} className="texto-blanco texto-arial">Prevención</a></li>
                    <li className={pagina == 'historia' ? 'fondo-naranja':''}><a onClick={() => setPagina('historia')} className="texto-blanco texto-arial">Historia</a></li>
                    <li className={pagina == 'integrantes' ? 'fondo-naranja':''}><a onClick={() => setPagina('integrantes')} className="texto-blanco texto-arial">Integrantes</a></li>
                    <li className={pagina == 'contacto' ? 'fondo-naranja':''}><a onClick={() => setPagina('contacto')} className="texto-blanco texto-arial">Contacto</a></li>
                    <li className={pagina == 'estadisticas' ? 'fondo-naranja':''}><a onClick={() => setPagina('estadisticas')} className="texto-blanco texto-arial">Estadísticas</a></li>
                </ul>
            </nav>
            <div className="emergencias-contacto texto-blanco texto-arial">
                <div className="menu-emergencias fondo-rojo">
                    <a href="tel:100">
                        <span className="icono">
                            <img src="images/icon-tel.png" weight="27" height="27" />
                        </span>
                        <span className="numero100 texto-blanco">100</span>
                        <span className="emergencias texto-blanco">Emergencias</span>
                    </a>
                </div>
                <div className="whatsapp">
                    <a href="https://wa.me/542944599788?text=Hola Bomberos de San Martín de los andes">
                        <span className="icono-whatsapp">
                            <img src="images/icon-ws.png" weight="20" height="20" />
                        </span>
                        <span className="numero texto-blanco"> 02944 599788</span>
                    </a>
                </div>
            </div>

            <Accesibilidad/>
        </header>
    );
}




export default Header;
