
import React, { useState, useEffect } from 'react';

const DatosClimaticos = () => {
  const [datos, setDatos] = useState({});

  useEffect(() => {
    fetch('https://bomberosvoluntariossma.org.ar/api/clima')
      .then(response => response.json())
      .then(data => setDatos(data));
  }, []);
  console.log(datos);
  return (
    <div className="weather-card">
      
      <div className="weather-info">
        <div className="weather-item">
          <span>Temperatura  </span><br/>
          <strong>{datos.temp}<small>°C</small></strong>
        </div>
        <br/>
        <div className="weather-item">
          <span>Humedad</span><br/>
          <strong>{datos.hum}<small>%</small></strong>
        </div>
        <br/>
        <div className="weather-item">
          <span>Viento</span><br/>
          <strong>{datos.direccion_viento} {datos.viento}<small>km/h</small></strong>
        </div>
      </div>
    </div>
  );
};

export default DatosClimaticos;
