import React, { useState, createContext } from 'react';

import PaginaInicio from './PaginaInicio';
import PaginaPrevencion from './PaginaPrevencion';
import PaginaHistoria from './PaginaHistoria';
import PaginaIntegrantes from './PaginaIntegrantes';
import PaginaContacto from './PaginaContacto';
import PaginaEquipo from './PaginaEquipo';
import PaginaEstadisticas from './PaginaEstadisticas';

export const PaginaContext = createContext();

function App() {
  const [pagina, setPagina] = useState(window.location.pathname.replace("/", ""));

  let returnPagina = <PaginaInicio/>;

  if(pagina == 'prevencion') {
    returnPagina = <PaginaPrevencion/>;
  }  
  else if(pagina == 'historia') {
    returnPagina = <PaginaHistoria/>;
  }  
  else if(pagina == 'integrantes') {
    returnPagina = <PaginaIntegrantes/>;
  }  
  else if(pagina == 'contacto') {
    returnPagina = <PaginaContacto/>;
  }  
  else if(pagina == 'equipo') {
    returnPagina = <PaginaEquipo/>;
  }  
  else if(pagina == 'estadisticas') {
    returnPagina = <PaginaEstadisticas/>;
  }    

  window.history.pushState(null, '', (pagina));

  return (
      <PaginaContext.Provider value={{ pagina, setPagina }}>
        <link rel="stylesheet" href={ 'styles/style_'+pagina+'.css'} type="text/css"/>
        
        {returnPagina}
      </PaginaContext.Provider>
  );
}



export default App;
