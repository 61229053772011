import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';


function CuerpoEquipo() {
  return (
    <section class="cuerpo fondo-negro">
        <a href="http://www.bitcotesma.ar" target="_blank" rel="noopener noreferrer">
            <img src="images/BIT-cotesma.png" alt=""/>
        </a>      
        <div class="video">
            <video controls autoplay muted>
                <source src="videos/f40-programacion-web.mp4" type="video/mp4"/>
            </video>            
        </div>

        <h2 class="texto-montserrat texto-blanco">DESARROLLADORES DE LA WEB</h2>
        <div class="equipo">

            <span class="miembro texto-blanco texto-montserrat">
                <img src="images/equipo/jair_betancur.jpg" alt=""/>
                <h4>JAIR BETANCUR</h4>
                <h6>Desarrollador</h6>

            </span>            

            <span class="miembro texto-blanco texto-montserrat">
                <img src="images/equipo/raimundo_pilotto.jpg"/>
                <h4>RAIMUNDO PILOTTO</h4>
                <h6>Desarrollador</h6>

            </span>
                        

            <span class="miembro texto-blanco texto-montserrat">
                <img src="images/equipo/federico_fuentes.jpeg" alt=""/>
                <h4>FEDERICO FUENTES</h4>
                <h6>Desarrollador</h6>

            </span>
         

            
            
            <span class="miembro texto-blanco texto-montserrat">
                <img src="images/equipo/martin_elizalde.jpg" alt=""/>
                <h4>MARTÍN ELIZALDE</h4>
                <h6>Desarrollador</h6>

            </span>
            
            
            <span class="miembro texto-blanco texto-montserrat">
                <img src="images/equipo/marcos_vallejos.jpg" alt=""/>
                <h4>MARCOS VALLEJOS</h4>
                <h6>Facilitador</h6>

            </span>
            

        </div>

        
        

    </section>

  );
}



function PaginaEquipo() {
  return (
    <div>
      <Header />

      <CuerpoEquipo />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaEquipo;
