import React from "react";

function BotonWS() {
    return (
        <section class="boton-flotante">
            <a href="https://api.whatsapp.com/send?phone=5492944599788" class="boton-wapp" target="_blank">
                <i>
                    <img src="images/icon-ws.png"/>
                </i>
            </a>
        </section>
    );
};



export default BotonWS;
