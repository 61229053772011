import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';


function CuerpoPrevencion() {
    return (
      
    <section class="cuerpo campania-prevencion">


      <div class="flex-container" id="monixido">
          <div class="contenido">
              <h2 class="texto-negro">Prevención de intoxicaciones con Monóxido de Carbono</h2>
              <p class="texto-negro">
                  El monóxido de carbono (CO) es un gas incoloro, inodoro y altamente venenoso que se produce por
                  la combustión incompleta de combustibles como el gas, la madera, el carbón y los combustibles.
                  La intoxicación por CO puede ser extremadamente peligrosa e incluso mortal. <br /><br />A
                  continuación, se presentan algunas medidas esenciales para prevenir la exposición al monóxido de
                  carbono:<br />

                  Instalación de detectores de CO:<br />
                  - Instala detectores de monóxido de carbono en cada nivel de tu hogar, especialmente cerca de
                  las
                  áreas de dormir.<br />
                  - Reemplaza las baterías de los detectores según las recomendaciones del fabricante y prueba los
                  dispositivos al menos una vez al mes.<br /><br />

                  Mantenimiento de aparatos de combustión:<br />
                  - Realiza un mantenimiento regular de todos los aparatos de combustión, como calentadores,
                  estufas, chimeneas y calderas, para asegurarte de que funcionan correctamente y en condiciones
                  seguras.<br />
                  - Contrata a un técnico profesional para inspeccionar y limpiar los sistemas de calefacción y
                  chimeneas una vez al año.<br /><br />

                  Ventilación adecuada:<br />
                  - Asegúrate de que los aparatos de combustión tengan una ventilación adecuada hacia el exterior.
                  Nunca bloquees las salidas de ventilación o los conductos de aire.<br /><br />

                  Uso seguro de calentadores y generadores:<br />
                  - No utilices calentadores de queroseno o generadores portátiles dentro de la casa, el garaje o
                  cualquier espacio cerrado sin ventilación adecuada.<br />
                  Coloca los generadores al aire libre y a una distancia segura de ventanas, puertas y conductos
                  de ventilación.<br /><br />

                  Precauciones en vehículos:<br />
                  - No dejes el motor del automóvil en marcha dentro del garaje, incluso si la puerta está
                  abierta.
                  Asegúrate de que el tubo de escape esté despejado y no bloqueado por nieve, barro u otros
                  escombros.<br /><br />

                  Síntomas de intoxicación por CO:<br />
                  - Reconoce los síntomas de intoxicación por monóxido de carbono, que incluyen dolores de cabeza,
                  mareos, náuseas, debilidad, confusión y pérdida de conciencia.<br />
                  - Si sospechas una intoxicación por CO, evacúa a todas las personas y mascotas de la vivienda de
                  inmediato y busca atención médica. Llama a los servicios de emergencia y no reingreses a la
                  vivienda hasta que se haya hecho una revisión adecuada.<br />
                  - Tomar estas precauciones puede ayudar a proteger a ti y a tu familia de los peligros del
                  monóxido de carbono y garantizar un hogar seguro y saludable.

              </p>

          </div>

          <div class="media">
              <div class="imagenM">
                  <video controls autoplay muted>
                      <source src="videos/monoxido.mp4" type="video/mp4"/>
                  </video>
              </div>
          </div>
      </div>



      <div class="flex-container" id="estufa">
          <div class="contenido">
              <h2 class="texto-negro">Uso responsable de estufas a leña</h2>
              <p class="texto-negro">Las estufas a leña son una opción popular para calentar hogares,
                  especialmente en áreas rurales. No obstante, para garantizar la seguridad y la eficiencia, es
                  crucial utilizarlas de manera responsable. A continuación, se presentan algunas recomendaciones
                  clave para el uso seguro y eficiente de estufas a leña:<br /><br />

                  Instalación adecuada:<br />
                  - Asegúrate de que la estufa a leña esté instalada por un profesional calificado. La instalación
                  debe cumplir con todas las normas locales de seguridad y construcción.<br />
                  - Mantén una distancia segura entre la estufa y cualquier material inflamable, como cortinas,
                  muebles y paredes.<br /><br />

                  Uso de leña adecuada:<br />
                  - Utiliza únicamente leña seca y bien curada para minimizar la generación de humo y creosota. La
                  madera húmeda no solo quema de forma ineficiente, sino que también produce más creosota, lo que
                  aumenta el riesgo de incendios en la chimenea.<br />
                  - Evita quemar madera tratada, pintada o contaminada con productos químicos, ya que pueden
                  liberar
                  toxinas peligrosas al aire.<br /><br />

                  Encendido y mantenimiento del fuego:<br />
                  - Utiliza métodos de encendido seguros, como encendedores de fuego comerciales o papel
                  periódico,
                  nunca líquidos inflamables como gasolina o disolventes.<br />
                  - Una vez encendido, mantén el fuego bajo control y no sobrecargues la estufa con demasiada leña
                  de una vez.<br />
                  - Ajusta las entradas de aire para mantener una combustión eficiente. Una buena combustión
                  produce
                  menos humo y más calor.<br /><br />

                  Ventilación adecuada:<br />
                  - Asegúrate de que la estufa esté conectada a una chimenea o conducto de humos que ventile
                  adecuadamente al exterior.<br />
                  - La chimenea debe ser inspeccionada y limpiada profesionalmente al menos una vez al año para
                  prevenir la acumulación de creosota, que puede causar incendios.<br />
                  - Nunca cierres completamente la entrada de aire de la estufa mientras el fuego esté encendido,
                  ya
                  que la falta de oxígeno puede provocar la producción de monóxido de carbono.<br /><br />

                  Eliminación de cenizas:<br />
                  - Retira las cenizas de la estufa regularmente para mantener un buen flujo de aire.
                  - Deposita las cenizas en un recipiente metálico con tapa y almacénalas lejos de materiales
                  inflamables hasta que estén completamente frías. <br /><br />

                  Almacenamiento de la leña:<br />
                  - Guarda la leña en un lugar seco y ventilado, y protege los suministros de leña adicionales de
                  la
                  humedad.<br />
                  - Mantén la leña almacenada al menos a 3 metros de la vivienda para evitar el riesgo de
                  incendio.
                  <br /><br />
                  Seguridad general:<br />
                  - Mantén a los niños y mascotas lejos de la estufa cuando esté encendida.<br />
                  - Usa guantes protectores y herramientas adecuadas al manejar la madera y ajustar la
                  estufa.<br /><br />
                  Siguiendo estas recomendaciones, podrás disfrutar de tu estufa a leña de manera segura y
                  eficiente, proporcionando un ambiente cálido y acogedor en tu hogar durante los meses fríos.

              </p>

              <img src="images/prevencion/estufa.png" alt=""/>
          </div>


      </div>

      <div class="flex-container" id="chimenea">
          <div class="contenido">
              <h2 class="texto-negro">Uso y mantenimiento de chimeneas</h2>
              <p class="texto-negro">
                  Encendido del Fuego:<br />
                  Utiliza solo leña seca y bien curada. La madera húmeda puede generar excesivo humo y aumentar la
                  acumulación de creosota, un residuo inflamable.<br />
                  Evita quemar materiales como cartón, basura o madera tratada/pintada, ya que pueden liberar
                  sustancias tóxicas.<br />
                  Usa métodos seguros para encender el fuego, como encendedores de fuego comercialmente
                  disponibles o papel periódico, y no líquidos inflamables como gasolina o querosene.<br /><br />

                  Mantenimiento del Fuego:<br />
                  Una vez que el fuego esté encendido, mantén la entrada de aire abierta para asegurar una
                  combustión completa y eficiente.<br />
                  Alimenta el fuego de manera moderada, evitando sobrecargar la chimenea con demasiada leña de una
                  vez.<br />
                  <br />
                  Ventilación:<br />
                  Asegúrate de que la chimenea tenga un tiro adecuado. Una buena ventilación permite que el humo y
                  los gases de combustión sean expulsados de manera segura hacia el exterior.<br />
                  Si la chimenea no está tirando correctamente, verifica si hay obstrucciones o problemas
                  estructurales.<br />
                  <br />

                  <b>Mantenimiento de Chimeneas</b><br /><br />
                  Inspección Anual:<br />
                  Contrata a un profesional certificado para inspeccionar la chimenea de tu hogar al menos una vez
                  al año. Esta inspección debe incluir la verificación de grietas, suciedad, acumulación de
                  creosota y cualquier obstrucción en el conducto de humos.<br />
                  <br />
                  Limpieza Regular:<br />
                  La limpieza regular de la chimenea es esencial para evitar acumulaciones peligrosas de creosota
                  y hollín. Un limpiador de chimeneas profesional debe realizar esta tarea, ya que puede ser
                  peligrosa para personas no capacitadas.<br />
                  Como regla general, las chimeneas de leña deben limpiarse cada 50 usos o al menos una vez por
                  temporada de uso intensivo.<br />
                  <br />
                  Cuidado de la Chimenea:<br />
                  Verifica periódicamente la mampostería y la estructura de la chimenea para detectar señales de
                  desgaste o daños.<br />
                  Mantén la chimenea y la zona circundante libres de materiales inflamables. Coloca una rejilla o
                  pantalla de seguridad delante de la abertura de la chimenea para prevenir que chispas o brasas
                  salten fuera.
                  <br /><br />
                  Eliminación de Cenizas:<br />
                  Limpia las cenizas de la caja de fuego con regularidad, pero asegúrate de que estén
                  completamente frías antes de hacerlo.<br />
                  Guarda las cenizas en un recipiente metálico con tapa y almacénalas lejos de materiales
                  inflamables hasta que puedan desecharse de manera segura.<br />
                  Seguridad en el Uso de la Chimenea<br /><br />

                  Supervisión Constante:<br />
                  Nunca dejes un fuego encendido desatendido en la chimenea.<br />
                  Asegúrate de apagar completamente el fuego antes de irte a dormir o salir de casa.<br /><br />

                  Protección Infantil:<br />
                  Mantén a los niños y mascotas a una distancia segura de la chimenea.<br />
                  Siguiendo estas prácticas, puedes disfrutar de tu chimenea de manera segura y eficiente durante
                  muchos años, creando un ambiente cálido y acogedor en tu hogar.

              </p>

          </div>


          <div class="media">
              <div class="imagenM">
                  <video controls autoplay muted>
                      <source src="videos/chimeneas.mp4" type="video/mp4"/>
                  </video>
              </div>
          </div>                

      </div>

      <div class="flex-container" id="incendio-domicilio">
          <div class="contenido">
              <h2 class="texto-negro">Prevención de incendios en domicilios</h2>
              <p class="texto-negro">La prevención de incendios en el hogar es fundamental para proteger tu hogar
                  y a tus seres queridos. Aquí tienes una guía detallada con medidas y prácticas para reducir el
                  riesgo de incendios en tu domicilio:<br /><br />

                  <b>1. Instalación de Detectores de Humo</b><br />
                  Colocación: Instala detectores de humo en cada nivel de tu hogar, dentro y fuera de los
                  dormitorios y en el pasillo central de cada piso.<br />
                  Mantenimiento: Prueba los detectores de humo mensualmente y reemplaza las baterías al menos una
                  vez al año. Sustituye los detectores cada 10 años o según las recomendaciones del fabricante.
                  <br /><br />

                  <b>2. Equipamiento de Seguridad</b><br />
                  Extintores: Ten al menos un extintor de incendios en cada nivel de la casa y en la cocina.
                  Familiarízate con su uso y asegúrate de que todos en el hogar sepan cómo utilizarlo.<br />
                  Rociadores de Incendios: Considera la instalación de un sistema de rociadores de incendios,
                  especialmente si estás construyendo o renovando tu hogar.<br /><br />

                  <b>3. Cocina Segura</b><br />
                  Atención Constante: Nunca dejes la cocina desatendida mientras cocinas. Los incendios de cocina
                  son una de las principales causas de incendios domésticos.<br />
                  Materiales Inflamables: Mantén materiales inflamables, como toallas y agarraderas, alejados de
                  la estufa.<br />
                  Limpieza Regular: Limpia regularmente la campana y los filtros de la cocina para evitar la
                  acumulación de grasa.<br /><br />

                  <b>4. Electricidad Segura</b><br />
                  Revisiones: Inspecciona periódicamente el sistema eléctrico de tu hogar. Busca signos de cables
                  desgastados o enchufes sobrecargados.<br />
                  Uso Adecuado de Enchufes: Evita el uso de múltiples adaptadores en una sola toma de corriente y
                  no sobrecargues los enchufes.<br />
                  Calefactores Portátiles: Utiliza los calefactores portátiles según las instrucciones del
                  fabricante y mantenlos alejados de cortinas, muebles y otros materiales
                  combustibles.<br /><br />

                  <b>5. Precauciones en el Uso de Calefacción</b><br />
                  Limpieza y Mantenimiento: Realiza un mantenimiento regular de las chimeneas, estufas a leña y
                  sistemas de calefacción central.<br />
                  Barreras de Seguridad: Utiliza pantallas de vidrio o de metal para evitar que las chispas de las
                  chimeneas salten hacia fuera.<br />
                  Colocación Segura: Mantén al menos un metro de distancia entre los calentadores y cualquier
                  material inflamable.<br />

                  <b>6. Cuidado con Velas y Encendedores</b><br />
                  Nunca Desatendidas: No dejes velas encendidas en una habitación desatendida.<br />
                  Colocación Segura: Coloca las velas lejos de cortinas, muebles y cualquier otro material
                  inflamable.<br />
                  Acceso Seguro: Mantén los fósforos y encendedores fuera del alcance de los niños.<br /><br />


                  <b>7. Almacenamiento de Productos Químicos</b><br />
                  Almacenamiento Adecuado: Guarda los productos inflamables en contenedores seguros y manténlos en
                  un área bien ventilada y alejada de fuentes de calor.<br />
                  Etiquetado: Asegúrate de que todos los productos químicos estén claramente
                  etiquetados.<br /><br />


                  <b>8. Plan de Evacuación</b><br />
                  Diseño del Plan: Desarrolla un plan de escape en caso de incendio. Asegúrate de que todos los
                  miembros de la familia conozcan dos rutas de escape desde cada habitación.<br />
                  Práctica Regular: Practica simulacros de incendio con tu familia periódicamente para asegurarte
                  de que todos sepan qué hacer en caso de emergencia.<br />
                  Punto de Reunión: Establece un punto de encuentro seguro fuera de la casa donde todos se
                  reunirán después de evacuar.<br /><br />

                  <b>9. Educación y Conciencia</b><br />
                  Educación Familiar: Educa a todos los miembros de la familia, incluidos los niños, sobre los
                  peligros del fuego y cómo prevenir incendios.<br />
                  Visitas a los Bomberos: Considera organizar una visita a la estación de bomberos local para que
                  los niños puedan aprender directamente de los profesionales.<br />
                  Siguiendo estas recomendaciones, podrás reducir significativamente el riesgo de incendios en tu
                  hogar y mantener a tu familia segura. ¡Tu seguridad y la de tus seres queridos es lo más
                  importante!
              </p>
          </div>

          <div class="media">
              <div class="imagenM">

                  <div class="slider-container">
                      <div class="slide active">
                          <img src="images/prevencion/incendios1.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios2.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios3.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios4.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios5.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios6.jpeg" alt="Slide 1"/>
                      </div>
                      <div class="slide">
                          <img src="images/prevencion/incendios7.jpeg" alt="Slide 1"/>
                      </div>
                      <button class="prev">&#10094;</button>
                      <button class="next">&#10095;</button>
                  </div>

              </div>
          </div>
      </div>

      <div class="flex-container" id="incendio-forestal">
          <div class="contenido">
              <h2 class="texto-negro">Prevención de incendios forestales y de interfaces</h2>
              <p class="texto-negro">
                  Los incendios forestales y de interfaces pueden tener consecuencias
                  devastadoras para el medio ambiente, las propiedades y las vidas humanas. Aquí tienes una guía
                  para ayudar a prevenir este tipo de incendios:<br /><br />
                  <b>Prevención de Incendios Forestales</b><br /><br />
                  Manejo Responsable del Fuego:
                  Fuegos Controlados: Solo realiza fuegos al aire libre en áreas y épocas permitidas. Asegúrate de
                  contar con las autorizaciones necesarias y las herramientas para controlarlo.<br />
                  Extinción: Asegúrate de extinguir completamente las fogatas antes de abandonar el área. Usa agua
                  o tierra y revuelve las cenizas hasta que estén frías al tacto.<br /><br />
                  Evitar el Uso de Elementos Inflamables:
                  Materiales Pirotécnicos: No uses fuegos artificiales, petardos, o bengalas en áreas forestales.
                  Cigarrillos: Si fumas, asegúrate de apagar completamente los cigarrillos y no los tires en áreas
                  naturales.<br /><br />
                  Mantenimiento del Terreno:<br />
                  Limpieza Regular: Mantén el área alrededor de tu propiedad libre de hojas secas, ramas y maleza.
                  Poda y Raleo: Poda regularmente los árboles y arbustos para eliminar las ramas bajas y reducir
                  la continuidad vertical del combustible.<br /><br />
                  Uso de Materiales Resistentes al Fuego:<br />
                  Techos y Paredes: Usa materiales no combustibles para la construcción de techos y paredes, como
                  tejas de cerámica, metal, o fibrocemento.<br />
                  Ventanas y Aberturas: Usa vidrio doble o de seguridad y asegura que las aberturas estén
                  protegidas.<br /><br />
                  Sistemas de Riego y Reservas de Agua:<br />
                  Riego Automático: Instala sistemas de riego en los jardines y áreas verdes para mantener la
                  humedad del suelo y las plantas.<br />
                  Depósitos de Agua: Instala depósitos de agua y bombas que puedan ser usadas por los bomberos en
                  caso de emergencia.
              </p>
              <img src="images/prevencion/incendiosforestales.jpg"/>
          </div>

      </div>
      {false && (
      <div class="flex-container" id="prehospitalaria">
          <div class="contenido">
              <h2 class="texto-negro">Atención Prehospitalaria</h2>
              <p class="texto-negro">
                  Atención Prehospitalaria: Aspectos Clave y Procedimientos Básicos<br /><br />
                  <b>Cadena de Supervivencia</b><br />
                  Acceso Rápido: Llamada al 100 o sistema de emergencia.<br />
                  Primeros Auxilios: Proporcionados por testigos o primeros respondientes.<br />
                  Atención Avanzada en el Lugar: Realizada por personal de emergencia.<br />
                  Transporte Seguro: Al centro médico adecuado.<br /><br />
                  <b>Evaluación Primaria (ABCDE)</b><br />
                  A (Airway): Asegurar y mantener la vía aérea.<br />
                  B (Breathing): Evaluar respiración y administrar oxígeno.<br />
                  C (Circulation): Controlar hemorragias y soportar circulación.<br />
                  D (Disability): Evaluar capacidad neurológica.<br />
                  E (Exposure): Exponer al paciente para identificar lesiones.<br /><br />
                  <b>Evaluación Secundaria</b><br />
                  Historia Médica: Utilizando el acrónimo SAMPLE (Signos y síntomas, Alergias, Medicamentos,
                  Pasado médico relevante, Última comida, Eventos relacionados).<br />
                  Exploración Física: Examen completo “de la cabeza a los pies”.<br /><br />
                  <b>Intervenciones Comunes</b><br />
                  RCP: Reanimación Cardio Pulmonar.<br />
                  Oxígeno: Administración a través de mascarillas o cánulas.<br />
                  Control de Hemorragias: Presión directa o torniquetes.<br />
                  Inmovilización: Uso de férulas, tablones espinales, collares cervicales.<br />
                  Defibrilación: Uso de desfibriladores externos automáticos (DEA).<br /><br />
                  <b>Equipamiento Esencial</b><br />
                  Primeros Auxilios: Vendas, gasas, tijeras, guantes.<br />
                  Equipo Avanzado: Desfibriladores, equipos de oxigenoterapia.<br />
                  Inmovilización: Tablas, collares cervicales, férulas.<br /><br />
                  <b>Comunicación</b><br />
                  Con el Paciente: Explicar procedimientos y obtener consentimiento.<br />
                  Con Servicios de Emergencia: Informar sobre situación y estado del paciente.<br />
                  Con el Hospital: Anticipar la llegada y estado del paciente.<br /><br />
                  <b>Seguridad</b><br />
                  Evaluación del Escenario: Confirmar que el lugar es seguro.<br />
                  Protección Personal: Uso de guantes, mascarillas.<br />
                  Protección del Paciente: Evitar movimientos innecesarios y proteger del entorno.<br /><br />
                  <b>Capacitación</b><br />
                  Entrenamiento Regular: En soporte vital básico y avanzado.<br />
                  Simulacros: Prácticas periódicas de situaciones de emergencia.<br />
                  Certificaciones: Asegurar certificación del personal.<br /><br />
                  <b>Protocolos</b><br />
                  Seguir Guías: De organizaciones como AHA e ILCOR.<br />
                  Adaptación Local: A normativas y recursos específicos de la región.<br /><br />
                  <b>Documentación</b><br />
                  Historiales Clínicos: Registro detallado de evaluaciones y tratamiento.<br />
                  Formularios de Emergencia: Completar para la transferencia al hospital.<br /><br />
                  La atención prehospitalaria eficiente y rápida puede salvar vidas y mejorar significativamente
                  los resultados del paciente. Siguiendo estos principios clave, los primeros respondientes pueden
                  proporcionar una asistencia vital antes de llegar al hospital.

              </p>
              <img src="images/prevencion/hospitalaria.jpg"/>
          </div>

      </div>
      )}




      <script src="js/prevencion.js"></script>
    
    </section>


  );
}



function PaginaPrevencion() {
  return (
    <div>
      <Header />
      <Encabezado />
      <CuerpoPrevencion />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaPrevencion;
