import React, { useContext } from 'react';
import { PaginaContext } from './App'; // Importamos el contexto

import Header from './Componentes/Header';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';
import DatosClimaticos from './Componentes/DatosClimaticos';
import AlertasClimaticos from './Componentes/AlertasClimaticos';
import Tips from './Componentes/Tips';
import Indice from './Componentes/Indice';


function CuerpoInicio() {
    const { pagina, setPagina } = useContext(PaginaContext);

    return (
      <div>

        <section class="home">
                <div class="imagen1 texto-blanco">
                        <h1 class="texto1 texto-montserrat texto-blanco">"SACRIFICIO, ABNEGACIÓN Y VALOR"</h1> 
                        <div class="logo-y-boton">
                            <img class="logobyn" src="images/logo-ByN.png" width="300" height="300"/>
                            <div class="boton">
                                <a href="#colaborar" class="boton" >
                                    <img src="images/boton-colaborar.png" width="250" height="65"/>
                                </a>
                            </div>
                        </div>
                </div>
                <div class="imagen2"></div>
            </section>






            <section class="nueva-version-prevencion prevencion fondo-amarillo">

                <h2>La mejor forma de ayudarnos es la PREVENCIÓN</h2>

                <div class="linea-prevencion">

                    <div class="caja-blanca fondo-blanco">
                        <h1>Datos climaticos</h1>
                        <DatosClimaticos/>
                        <AlertasClimaticos/>
                    </div>

                    <div class="caja-blanca fondo-blanco">
                        <Indice/>
                    </div>
                    
                    <div class="caja-blanca fondo-blanco">
                        <Tips/>
                    </div>                    

                </div>

                <div>

                    <p>La mejor prevención es la información. Enterate de nuestras campañas de prevención, y no esperes a que una desgracia ocurra, actua hoy con responsabilidad y prevención para evitar futuras tragedias. Nuestra seguridad y la de nuestros seres queridos depende de ello.</p>

                </div>


                <div class="botones-prevencion fondo-blanco" id="campania">
                    
                    <a onClick={() => setPagina('prevencion')}>
                        <img src="images/prevencion/monoxido.gif" width="150" height="150"/> 
                        <br/> 
                        <p class="prevencion texto-negro"> Intoxicaciones con Monóxido de Carbono </p>
                    </a>

                    <a onClick={() => setPagina('prevencion')}>
                        <img src="images/prevencion/estufa.gif" width="150" height="150"/> 
                        <br/> 
                        <p class="prevencion texto-negro"> Uso responsable de estufas a leña </p>
                    </a>
                    <a onClick={() => setPagina('prevencion')}>
                        <img src="images/prevencion/chimeneas.gif" width="150" height="150"/> 
                        <br/> 
                        <p class="prevencion texto-negro"> Uso y mantenimiento de chimeneas </p>
                    </a>
                    <a onClick={() => setPagina('prevencion')}>
                        <img src="images/prevencion/domicilio.gif" width="150" height="150"/> 
                        <br/> 
                        <p class="prevencion texto-negro"> Prevención de incendios en domicilios</p> 
                    </a>
                    <a onClick={() => setPagina('prevencion')}>
                        <img src="images/prevencion/forestal.gif" width="150" height="150"/> 
                        <br/> 
                        <p class="prevencion texto-negro"> Prevención de incendios forestales </p>
                    </a>
                    
                    
   
                    

                </div>   

            

            </section>

        <script src="js/datos.js"></script>
        <script defer src="js/contenido_dinamico_index.js"></script>

            <section class="historia">
                <div class="fondo-negro"> 
                    <img id="forma" src="images/forma-historia-izq.png" alt="forma"/> 
                    <h1 class="historia-cuartel texto-amarillo texto-rye">HISTORIA DEL CUARTEL</h1>        
                    <img id="forma2" src="images/forma-historia-der.png" alt="forma2"/>
                </div>
                <div class="foto-historica">
                    <div class="decoracion izquierda">
                        <img src="images/forma-historia-vert.png" alt=""/>
                    </div>
                    <span id="textoo" class="texto-arial texto-blanco">
                        <span class="scrooleable">
                            Un 31 de enero de 1952 nacía la Asociación de Bomberos Voluntarios de San Martín de los Andes con apellidos como: Leotta, Creide, Mera, Del Hoyo, Vita, Demateo, Orazi, Astete, Buamscha, Cuevas, Asmar, Gingins, Tossi, Ragusi y tantos otros conformaron el primer Cuerpo Activo y ComisiónDirectiva. 
                            <br/><br/>
                            Luego de diez años y por diversas circunstancias la Asociación dejó de funcionar delegando las funciones del Cuerpo Activo a la Policía Provincial. 
                            <br/><br/>
                            El 30 de noviembre del año 1975 se efectuó una nueva asamblea y en el año 1978, luego de un arduo trabajo por parte de algunos vecinos de Nuestra ciudad, se logró la recuperación de la sede social y bienes de calle General Roca retornando finalmente la prestación del servicio al sistema voluntario. 
                            <br/><br/>
                            Entendiendo que la estructura original establecida en el año 1952 fue la que le dio origen a la Asociación, y que corresponde efectuar el reconocimiento debido a aquel esfuerzo comunitario, se decidió que el día 31 de enero de 1952 sea la fecha oficial de la fundación de la Asociación de Bomberos Voluntarios de San Martín de los Andes. 
                            <br/><br/>
                        </span>
                        <div class="boton  fondo-blanco ">
                            <a onClick={() => setPagina('historia')} class="texto-celeste texto-arial">
                                LEER MÁS
                            </a>
                        </div>

                    </span>
                    <div class="decoracion derecha">
                        <img src="images/forma-historia-vert1.png" alt=""/>
                    </div>
                </div>
                <div class="historia-fin fondo-negro">

                </div>
            </section>

            <section class="cursosydonaciones fondo-naranja" id="colaborar">
                <div class="cursos">
                    <iframe src="https://www.youtube.com/embed/kIe4kgPxRPU?si=kDTjO0UhBQ5EL4Kp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>            
                </div>
                <div class="v-line"></div>
                <div class="donaciones">
                    <h2 class="texto-arial">¡AYUDANOS A PROTEGER NUESTRA CIUDAD!</h2>
                    <p class="texto-montserrat">Somos Bomberos Voluntarios y te necesitamos para poder seguir brindando nuestra ayuda a la comunidad. Con una mínima colaboración nos das una mano para poder mantener nuestro propio cuartel, mejorar nuestras herramientas y pagar los gastos operativos que genera un Cuartel de Bomberos.</p>
                    <div class="whatsapp">
                        <a href="https://wa.me/542944599788?text=Hola Bomberos de San Martín de los andes, quiero saber como colaborar.">
                            <span class="icono-whatsapp"><img src="images/icon-ws.png"/></span>
                            <span class="numero texto-blanco texto-montserrat"> Esperamos tu mensaje </span>
                        </a>
                    </div>
                    
                </div>
            </section>        
      </div>
    );
}



function Inicio() {
  return (
    <div>
      <Header/>
      <CuerpoInicio/>
      <Footer/>
      <Creditos/>
      <BotonWS/>
    </div>
  );
}

export default Inicio;
