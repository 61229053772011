import React, { useState, useEffect } from 'react';

function procesarAlertas(alertas) {
  const alertasVigentes = alertas.warnings.filter(alerta => {
    
      const fechaActual = new Date().toISOString().split('T')[0];
      return alerta.date === fechaActual;
  });

  const tiposDeAlerta = {
      37: "Lluvia",
      39: "Viento",
      40: "Niebla",
      41: "Tormenta",
      42: "Nevada",
      43: "Temp. Altas",
      44: "Temp. Bajas",
      45: "Ceniza volcánica",
      46: "Polvo",
      47: "Viento zonda",
      54: "Humo"
  };

  const nivelesDeAlerta = {
      1: "Nivel verde – Tranquilidad",
      2: "Nivel verde – Tranquilidad",
      3: "Nivel amarillo – Informate",
      4: "Nivel naranja – Preparate",
      5: "Nivel rojo – Seguí instrucciones oficiales"
  };

  return alertasVigentes.map(alerta => {
    console.log(alerta);
      const eventosFiltrados = alerta.events.filter(evento => evento.max_level >= 3);
      
      return {
          fecha: alerta.date,
          nivelMaximo: nivelesDeAlerta[alerta.max_level],
          eventos: eventosFiltrados.map(evento => ({
              tipo: tiposDeAlerta[evento.id],
              nivel: nivelesDeAlerta[evento.max_level]
          }))
      };
  }).filter(alerta => alerta.eventos.length > 0);
}



function AlertasClimaticos() {
  const [alertas, setAlertas] = useState([]);

  useEffect(() => {
    fetch('https://bomberosvoluntariossma.org.ar/api/alertas')
      .then(response => response.json())
      .then(data => {
        setAlertas(procesarAlertas(data));
      })
      .catch(error => console.error('Error al obtener las alertas:', error));
  }, []);



  console.log(alertas);

  return (
    <div>
      {alertas.length === 0 ? ( // Mostrar un mensaje si no hay alertas
        <p className='alertas-vigentes'>No hay alertas vigentes.</p>
      ) : (
        <ul>
          {alertas.map((alerta, index) => ( // Iterar sobre el array de alertas
            <li key={index}>
              <p>Fecha: {alerta.fecha}</p>
              <ul>
                {alerta.eventos.map((evento, eventIndex) => (
                  <li key={eventIndex}>
                    <div className="triangulo-amarillo">
                      <img className="icono_alerta" title="Alerta amarillo por viento" src="images/prevencion/viento.webp"/>
                    </div>
                    {evento.tipo}: {evento.nivel}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AlertasClimaticos;



  /* ---FALTARIA---
  1- a partir de los datos procesados (evento.tipo y evento.nivel) debemos ahora preguntar (condicional) que tipo
  de imagen mostrar (ver carpeta public/images/prevencion) en el tag img(icono_alerta) 
  y que classname vamos a darle al triangulo (o clase de css que da el color) por ej: triangulo-amarillo, triangulo-rojo, triangulo-naranja

  */











// function AlertasClimaticos() {
//   // Estado para almacenar los usuarios
//   const [alertas, setAlertas] = useState([]);

//   // useEffect para hacer la llamada a la API al cargar el componente
//   useEffect(() => {
//     // Llamada a la API para obtener los usuarios
//     fetch('https://bomberosvoluntariossma.org.ar/api/alertas')
//       .then(response => response.json())
//       .then(data => {
  
//         setAlertas(procesarAlertas(data));  // Guardamos los usuarios en el estado
       
//       })
//       .catch(error => console.error('Error al obtener los usuarios:', error));
//   }, []);

//   console.log(alertas); 
//   // Renderizamos la lista de usuarios
//   return (
//     <div>
//           <h1>Alertas</h1>
//           <ul>
//               <li>{alertas.area_id}</li>
//               <li>{alertas.warning}</li>
//           </ul>

//     </div>
//   );
// }

// export default AlertasClimaticos;