import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';
import CuerpoAct from './Componentes/CuerpoAct';
import Comision from './Componentes/Comision';

function CuerpoCuerpoActivo() {
  return (


    <section class="cuerpo fondo-naranja">
      
        <hr/>
        <p>El cuerpo activo constituye la razón de ser y el elemento fundamental para el cumplimiento del cometido de los Bomberos Voluntarios de San Martin de los Andes.</p>
        
        <h3>Comisión Directiva</h3>
        <hr />

        <Comision/>
        <br/>
        <hr />
        <br/>
        <CuerpoAct/>
        




    </section>
  );
}



function PaginaCuerpoActivo() {
  return (
    <div>
      <Header />
      <Encabezado />
      <CuerpoCuerpoActivo />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaCuerpoActivo;
