import React, { useState, useEffect } from 'react';

function FFMC(TEMP, RH, WIND, RAIN, FFMCPrev) {
  // Calcula el Fine Fuel Moisture Code de hoy
  // Parámetros:
  // TEMP: temperatura a las 12:00 LST en grados Celsius
  // RH: humedad relativa a las 12:00 LST en %
  // WIND: velocidad del viento a las 12:00 LST en kph
  // RAIN: lluvia acumulada en las últimas 24 horas en mm
  // FFMCPrev: FFMC del día anterior

  RH = Math.min(100.0, RH);
  let mo = 147.2 * (101.0 - FFMCPrev) / (59.5 + FFMCPrev);

  if (RAIN > 0.5) {
      let rf = RAIN - 0.5;

      let mr;
      if (mo <= 150.0) {
          mr = mo + 
               42.5 * rf * Math.exp(-100.0 / (251.0 - mo)) * (1.0 - Math.exp(-6.93 / rf));
      } else {
          mr = mo + 
               42.5 * rf * Math.exp(-100.0 / (251.0 - mo)) * (1.0 - Math.exp(-6.93 / rf)) +
               0.0015 * Math.pow(mo - 150.0, 2) * Math.sqrt(rf);
      }

      if (mr > 250.0) {
          mr = 250.0;
      }

      mo = mr;
  }

  let ed = 0.942 * Math.pow(RH, 0.679) + 
           11.0 * Math.exp((RH - 100.0) / 10.0) + 
           0.18 * (21.1 - TEMP) * (1.0 - Math.exp(-0.115 * RH));

  let m;
  if (mo > ed) {
      let ko = 0.424 * (1.0 - Math.pow(RH / 100.0, 1.7)) + 
               0.0694 * Math.sqrt(WIND) * (1.0 - Math.pow(RH / 100.0, 8));

      let kd = ko * 0.581 * Math.exp(0.0365 * TEMP);
      m = ed + (mo - ed) * Math.pow(10.0, -kd);
  } else {
      let ew = 0.618 * Math.pow(RH, 0.753) + 
               10.0 * Math.exp((RH - 100.0) / 10.0) + 
               0.18 * (21.1 - TEMP) * (1.0 - Math.exp(-0.115 * RH));

      if (mo < ew) {
          let k1 = 0.424 * (1.0 - Math.pow((100.0 - RH) / 100.0, 1.7)) + 
                           0.0694 * Math.sqrt(WIND) * (1.0 - Math.pow((100.0 - RH) / 100.0, 8));

          let kw = k1 * 0.581 * Math.exp(0.0365 * TEMP);
          m = ew - (ew - mo) * Math.pow(10.0, -kw);
      } else {
          m = mo;
      }
  }

  return 59.5 * (250.0 - m) / (147.2 + m);
}






function DMC(TEMP, RH, RAIN, DMCPrev, LAT, MONTH) {
  // Calcula el Duff Moisture Code de hoy
  // Parámetros:
  // TEMP: temperatura a las 12:00 LST en grados Celsius
  // RH: humedad relativa a las 12:00 LST en %
  // RAIN: lluvia acumulada en las últimas 24 horas en mm
  // DMCPrev: DMC del día anterior
  // LAT: latitud en grados decimales
  // MONTH: mes del año (1..12) para los cálculos del día actual

  RH = Math.min(100.0, RH);
  
  if (RAIN > 1.5) {
      let re = 0.92 * RAIN - 1.27;

      let mo = 20.0 + Math.exp(5.6348 - DMCPrev / 43.43);
      let b;

      if (DMCPrev <= 33.0) {
          b = 100.0 / (0.5 + 0.3 * DMCPrev);
      } else if (DMCPrev <= 65.0) {
          b = 14.0 - 1.3 * Math.log(DMCPrev);
      } else {
          b = 6.2 * Math.log(DMCPrev) - 17.2;
      }

      let mr = mo + 1000.0 * re / (48.77 + b * re);
      let pr = 244.72 - 43.43 * Math.log(mr - 20.0);

      if (pr > 0.0) {
          DMCPrev = pr;
      } else {
          DMCPrev = 0.0;
      }
  }

  let k = 0.0;
  if (TEMP > -1.1) {
      let d1 = DayLength(LAT, MONTH); // Asegúrate de definir esta función
      k = 1.894 * (TEMP + 1.1) * (100.0 - RH) * d1 * 0.000001;
  }

  return DMCPrev + 100.0 * k;
}

function DC(TEMP, RAIN, DCPrev, LAT, MONTH) {
  // Calcula el Drought Code de hoy
  // Parámetros:
  // TEMP: temperatura a las 12:00 LST en grados Celsius
  // RAIN: lluvia acumulada en las últimas 24 horas en mm
  // DCPrev: DC del día anterior
  // LAT: latitud en grados decimales
  // MONTH: mes del año (1..12) para los cálculos del día actual

  if (RAIN > 2.8) {
      let rd = 0.83 * RAIN - 1.27;
      let Qo = 800.0 * Math.exp(-DCPrev / 400.0);
      let Qr = Qo + 3.937 * rd;
      let Dr = 400.0 * Math.log(800.0 / Qr);

      if (Dr > 0.0) {
          DCPrev = Dr;
      } else {
          DCPrev = 0.0;
      }
  }

  let Lf = DryingFactor(LAT, MONTH - 1); // Asegúrate de definir esta función

  let V;
  if (TEMP > -2.8) {
      V = 0.36 * (TEMP + 2.8) + Lf;
  } else {
      V = Lf;
  }

  if (V < 0.0) {
      V = 0.0;
  }

  let D = DCPrev + 0.5 * V;

  return D;
}

function DayLength(Latitude, MONTH) {
  // Aproxima la duración del día dada la latitud y el mes
  // Parámetros:
  // Latitude: latitud en grados decimales
  // MONTH: mes del año (1-12)

  const DayLength46N = [6.5, 7.5, 9.0, 12.8, 13.9, 13.9, 12.4, 10.9, 9.4, 8.0, 7.0, 6.0];
  const DayLength20N = [7.9, 8.4, 8.9, 9.5, 9.9, 10.2, 10.1, 9.7, 9.1, 8.6, 8.1, 7.8];
  const DayLength20S = [10.1, 9.6, 9.1, 8.5, 8.1, 7.8, 7.9, 8.3, 8.9, 9.4, 9.9, 10.2];
  const DayLength40S = [11.5, 10.5, 9.2, 7.9, 6.8, 6.2, 6.5, 7.4, 8.7, 10.0, 11.2, 11.8];

  let retVal = null;

  if (Latitude <= 90 && Latitude > 33) {
      retVal = DayLength46N[MONTH - 1];
  } else if (Latitude <= 33 && Latitude > 0.0) {
      retVal = DayLength20N[MONTH - 1];
  } else if (Latitude <= 0.0 && Latitude > -30.0) {
      retVal = DayLength20S[MONTH - 1];
  } else if (Latitude <= -30.0 && Latitude >= -90.0) {
      retVal = DayLength40S[MONTH - 1];
  }

  if (retVal === null) {
      throw new Error(`Invalid Latitude: ${Latitude}`);
  }

  return retVal;
}

function calcFWI(MONTH, TEMP, RH, WIND, RAIN, FFMCPrev, DMCPrev, DCPrev, LAT) {
  // Calcula el FWI de hoy
  // Parámetros:
  // TEMP: temperatura a las 12:00 LST en grados Celsius
  // RH: humedad relativa a las 12:00 LST en %
  // WIND: velocidad del viento a las 12:00 LST en kph
  // RAIN: lluvia acumulada en las últimas 24 horas en mm
  // FFMCPrev: FFMC del día anterior
  // DMCPrev: DMC del día anterior
  // DCPrev: DC del día anterior
  // LAT: latitud en grados decimales

  const ffmc = FFMC(TEMP, RH, WIND, RAIN, FFMCPrev);
  const dmc = DMC(TEMP, RH, RAIN, DMCPrev, LAT, MONTH);
  const dc = DC(TEMP, RAIN, DCPrev, LAT, MONTH);
  const isi = ISI(WIND, ffmc);
  const bui = BUI(dmc, dc);
  const fwi = FWI(isi, bui);

  return fwi;
}

function LawsonEq1(DMC) {
  // Ecuación de Lawson para relacionar DMC con el contenido de humedad del suelo
  // Parámetros:
  // DMC: Duff Moisture Code

  return Math.exp((DMC - 244.7) / -43.4) + 20.0;
}

function LawsonEq2(DMC) {
  // Ecuación de Lawson para relacionar DMC con el contenido de humedad del suelo
  // Parámetros:
  // DMC: Duff Moisture Code

  return Math.exp((DMC - 223.9) / -41.7) + 20.0;
}

function LawsonEq3(DMC) {
  // Ecuación de Lawson para relacionar DMC con el contenido de humedad del suelo
  // Parámetros:
  // DMC: Duff Moisture Code

  return Math.exp((DMC - 157.3) / -24.6) + 20;
}

function LawsonEq4(DMC) {
  // Ecuación de Lawson para relacionar DMC con el contenido de humedad del suelo
  // Parámetros:
  // DMC: Duff Moisture Code

  return Math.exp((DMC - 106.7) / -14.9) + 20.0;
}

function LawsonEq5(DMC) {
  // Ecuación de Lawson para relacionar DMC con el contenido de humedad del suelo
  // Parámetros:
  // DMC: Duff Moisture Code

  return Math.exp((DMC - 149.6) / -20.9);
}



function ISI(WIND, FFMC) {
  // Calcula el Initial Spread Index de hoy
  // Parámetros:
  // WIND: velocidad del viento a las 12:00 LST en kph
  // FFMC: FFMC del día actual

  let fWIND = Math.exp(0.05039 * WIND);
  let m = 147.2 * (101.0 - FFMC) / (59.5 + FFMC);
  let fF = 91.9 * Math.exp(-0.1386 * m) * (1.0 + Math.pow(m, 5.31) / 49300000.0);

  return 0.208 * fWIND * fF;
}


function BUI(DMC, DC) {
  // Calcula el Buildup Index de hoy
  // Parámetros:
  // DMC: Duff Moisture Code del día actual
  // DC: Drought Code del día actual

  let U;

  if (DMC <= 0.4 * DC) {
      U = 0.8 * DMC * DC / (DMC + 0.4 * DC);
  } else {
      U = DMC - (1.0 - 0.8 * DC / (DMC + 0.4 * DC)) * 
          (0.92 + Math.pow(0.0114 * DMC, 1.7));
  }

  return Math.max(U, 0.0);
}

function DryingFactor(Latitude, Month) {
  // Calcula el factor de secado basado en la latitud y el mes
  // Parámetros:
  // Latitude: latitud en grados decimales
  // Month: mes del año (0-11 para enero a diciembre)

  const LfN = [-1.6, -1.6, -1.6, 0.9, 3.8, 5.8, 6.4, 5.0, 2.4, 0.4, -1.6, -1.6];
  const LfS = [6.4, 5.0, 2.4, 0.4, -1.6, -1.6, -1.6, -1.6, -1.6, 0.9, 3.8, 5.8];

  let retVal;

  if (Latitude > 0) {
      retVal = LfN[Month];
  } else {
      retVal = LfS[Month];
  }

  return retVal;
}


function FWI(ISI, BUI) {
  // Calcula el Fire Weather Index de hoy
  // Parámetros:
  // ISI: ISI del día actual
  // BUI: BUI del día actual

  let fD;

  if (BUI <= 80.0) {
      fD = 0.626 * Math.pow(BUI, 0.809) + 2.0;
  } else {
      fD = 1000.0 / (25.0 + 108.64 * Math.exp(-0.023 * BUI));
  }

  let B = 0.1 * ISI * fD;
  let S;

  if (B > 1.0) {
      S = Math.exp(2.72 * Math.pow(0.434 * Math.log(B), 0.647));
  } else {
      S = B;
  }

  return S;
}

// Ejemplo de uso





function calcularInclinacion(valor) {
  let inclinacion;
  let probabilidad;

  if (valor > 85) {
      inclinacion = 180; // Siempre 180 si el valor es mayor a 85
      probabilidad = 'Extremo';
  } else if (valor > 59.8) {
      // Escalar entre 140° y 180°
      inclinacion = escalar(valor, 59.8, 85, 140, 180);
      probabilidad = 'Extremo';
  } else if (valor > 39.3) {
      // Escalar entre 105° y 140°
      inclinacion = escalar(valor, 39.4, 59.8, 105, 140);
      probabilidad = 'Muy Alto';
  } else if (valor > 20.7) {
      // Escalar entre 70° y 105°
      inclinacion = escalar(valor, 20.8, 39.3, 70, 105);
      probabilidad = 'Alto';
  } else if (valor > 8.2) {
      // Escalar entre 35° y 70°
      inclinacion = escalar(valor, 8.3, 20.7, 35, 70);
      probabilidad = 'Moderado';
  } else {
      // Escalar entre 0° y 35°
      inclinacion = escalar(valor, 0, 8.2, 0, 35);
      probabilidad = 'Bajo';
  }
console.log('*****'+probabilidad);
  return [inclinacion, probabilidad];
}

// Función para escalar un valor entre dos rangos
function escalar(valor, minInput, maxInput, minOutput, maxOutput) {
  return (
      ((valor - minInput) / (maxInput - minInput)) * (maxOutput - minOutput) + minOutput
  );
}



function Indice() {
  // Estado para almacenar los usuarios
  const [data, setData] = useState([]);
  const [probabilidad, setProbabilidad] = useState([]);
  const [inclinacion, setInclinacion] = useState([]);
  let valorFWI = 0;

  // useEffect para hacer la llamada a la API al cargar el componente
  useEffect(() => {
    // Llamada a la API para obtener los usuarios
    fetch('https://bomberosvoluntariossma.org.ar/api/fwi')
      .then(response => response.json())
      .then(data => {
        setData(data);  // Guardamos los usuarios en el estado
        if (data) {
          const {
            temperature,
            humidity,
            wind: { speed: windSpeed },
            rain,
            fwi_data: { FFMCPrev, DMCPrev, DCPrev },
            location: { coord: { lat } }
          } = data;
      
          // Puedes establecer un valor fijo para el mes si es necesario
          const month = new Date(data.date).getMonth() + 1; // Obtener el mes (1-12)
          valorFWI = 18;//calcFWI(month, temperature, humidity, windSpeed, rain, parseFloat(FFMCPrev), parseFloat(DMCPrev), parseFloat(DCPrev), lat);
          console.log('fwi:'+valorFWI);
          let res = calcularInclinacion(valorFWI);
          setInclinacion(res[0]);
          setProbabilidad(res[1]);
        }
      


      })
      .catch(error => console.error('Error al obtener los usuarios:', error));
  }, []);


   // Solo ejecutamos el cálculo si los datos han sido cargados
   
   
   //console.log(month, temperature, humidity, windSpeed, rain, FFMCPrev, DMCPrev, DCPrev, lat);

  // calcFWI(MONTH,TEMP,RH,WIND,RAIN,FFMCPrev,DMCPrev,DCPrev,LAT)
  //let valorFWI = calcFWI(1, 28, 16, 22, 0, 94.4, 219.4, 525.7, 40.2);
  // Asegúrate de incluir todas las funciones necesarias aquí...

    


  return (
    <div className="probabilidades">
        <h1>Probabilidad de incendio</h1>
        <h3 style={{color: (inclinacion > 70 ? 'red' : 'blue')}}>{probabilidad}</h3>
        

        <div className='fwi-fondo'>
          <img class="flechita" src="images/prevencion/fwi-flecha.png" style={{ '--rotate-angle': (inclinacion+'deg') }}/>
        </div>
    </div>
  );
  }
  export default Indice;



  /* ---FALTARIA---
  1- Completar los parametros de entrada(inputs) de la funcion calcFWI 
  con los valores que nos devuelve el JSON de la API que estan en la variable data.

  2- Dependiendo el numero obtenido en el calculo del FWI y la referencia del mismo, 
  debemos darle una rotación a la imagen de la flechia con la propiedad CSS transform: rotate(45deg);

  */