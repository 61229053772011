import React, { useEffect, useState } from 'react';

function obtenerDescripcionTipo(tipo) {
    const tipos = {
        37: 'Lluvia',
        39: 'Viento',
        40: 'Niebla',
        41: 'Tormenta',
        42: 'Nevada',
        43: 'Temp. Altas',
        44: 'Temp. Bajas',
        45: 'Ceniza volcánica',
        46: 'Polvo',
        47: 'Viento zonda',
        54: 'Humo'
    };
    return tipos[tipo] || 'Desconocido';
}

function obtenerDescripcionNivel(nivel) {
    const niveles = {
        1: 'Nivel verde - Tranquilidad',
        2: 'Nivel verde - Tranquilidad',
        3: 'Nivel amarillo - Infórmate',
        4: 'Nivel naranja - Prepárate',
        5: 'Nivel rojo - Sigue instrucciones oficiales'
    };
    return niveles[nivel] || 'Desconocido';
}

function obtenerTips(tipo) {
    const tips = {
        //VERANO
        1: [
            'Revisá las estufas y las chimeneas antes de encenderlas en invierno. ',
            'Ventilá los ambientes de tu casa todos los días para evitar acumulación de gases. ',
            'Antes de salir, asegurate de que tu auto esté en buenas condiciones. ',
            'Respetá las normas de tránsito y las velocidades permitidas. ',
            'Evitá hacer fuego en zonas no habilitadas para prevenir incendios forestales. ',
            'Almacená productos inflamables lejos de fuentes de calor o chispas. ',
            'No sobrecargues enchufes ni utilices cables en mal estado en tu hogar. '            
        ],
        //INVIERNO
        2: [
            'No uses hornallas ni artefactos a gas para calefaccionar tu hogar. ',
            'Ventilá los ambientes de tu casa todos los días para evitar acumulación de gases. ',
            'Instalá detectores de humo y gas y revisalos periódicamente',
            'Respetá las normas de tránsito y las velocidades permitidas. ',
            'No sobrecargues enchufes ni utilices cables en mal estado en tu hogar.',
            'Practicá un plan de evacuación en caso de emergencias con tu familia. '
        ],                
        37: [
            'Evita conducir en zonas inundadas.',
            'Mantén limpios los desagües y canaletas.',
            'Ten a mano un kit de emergencia con linterna, pilas y agua potable.'
        ],
        39: [
            'Asegura objetos sueltos en el exterior, como muebles de jardín y macetas.',
            'Evita caminar cerca de árboles y postes de luz.',
            'Cierra y asegura puertas y ventanas.'
        ],
        40: [
            'Conduce con las luces bajas encendidas.',
            'Reduce la velocidad y aumenta la distancia de seguridad entre vehículos.',
            'Utiliza las líneas de la carretera como guía.'
        ],
        41: [
            'Desconecta aparatos eléctricos para evitar daños por sobretensiones.',
            'Refúgiate en un lugar seguro, lejos de ventanas y puertas.',
            'Evita el uso de teléfonos y dispositivos electrónicos.'
        ],
        42: [
            'Mantén un suministro de alimentos y agua en casa.',
            'Usa ropa adecuada para el frío y evita la exposición prolongada.',
            'Limpia la nieve de las entradas y salidas de tu hogar.'
        ],
        43: [
            'Bebe agua frecuentemente y evita bebidas alcohólicas.',
            'Usa ropa ligera y de colores claros.',
            'Evita la exposición al sol durante las horas más calurosas del día.'
        ],
        44: [
            'Viste en capas para mantener el calor corporal.',
            'Asegúrate de que los sistemas de calefacción funcionen correctamente.',
            'Evita el uso de estufas de gas o carbón en espacios cerrados.'
        ],
        45: [
            'Usa mascarillas y gafas protectoras para evitar la inhalación de ceniza.',
            'Mantén las ventanas y puertas cerradas.',
            'Limpia la ceniza de techos y canaletas para evitar acumulaciones.'
        ],
        46: [
            'Cierra ventanas y puertas para evitar la entrada de polvo.',
            'Usa mascarillas si necesitas salir al exterior.',
            'Mantén los filtros de aire de tu hogar limpios.'
        ],
        47: [
            'Mantén hidratación adecuada.',
            'Evita actividades físicas intensas al aire libre.',
            'Cierra ventanas y puertas para evitar la entrada de aire caliente.'
        ],
        54: [
            'Usa mascarillas para protegerte de la inhalación de humo.',
            'Mantén las ventanas y puertas cerradas.'
        ]
    };

    if(tipo == 0) {
        const month = new Date().getMonth() + 1;
        console.log('mmmm '+month);

        if(month > 9 && month < 3) {
            //VERANO
            return tips[1];
        }
        else {
            //INVIERNO
            return tips[2];
        }
    }
    else {
        return tips[tipo];
    }
    
}

const Tips = () => {
    const [alertas, setAlertas] = useState([]);
    const [tipActual, setTipActual] = useState('');

    useEffect(() => {
        // Llamada a la API para obtener las alertas
        fetch('https://bomberosvoluntariossma.org.ar/api/alertas')
            .then(response => response.json())
            .then(data => {
                setAlertas(data);
                mostrarTipsAlternados(data);
            })
            .catch(error => console.error('Error al obtener las alertas:', error));
    }, []);

    const mostrarTipsAlternados = (alertas) => {

        const today = new Date().toISOString().split('T')[0]; // Obtener la fecha de hoy en formato YYYY-MM-DD
        const tipsPorMostrar = [];

        tipsPorMostrar.push(...obtenerTips(0)); // Agregar los tips a la lista

        // Filtrar las alertas por la fecha de hoy y niveles 3, 4 o 5
        alertas.warnings.forEach(warning => {
            if (warning.date === today) {
                warning.events.forEach(event => {
                    const tipo = event.id;
                    const nivel = event.max_level;

                    // Solo considerar niveles 3, 4 y 5
                    if (nivel >= 3) {
                        const tipoDescripcion = obtenerDescripcionTipo(tipo);
                        const nivelDescripcion = obtenerDescripcionNivel(nivel);
                        const tips = obtenerTips(tipo);
                        
                        // Agregar una línea descriptiva de la alerta
                        tipsPorMostrar.push(`Alerta: ${tipoDescripcion} - ${nivelDescripcion}`);
                        tipsPorMostrar.push(...tips); // Agregar los tips a la lista
                    }
                });
            }
        });

        

        const mostrarTip = () => {
            console.log('----');
            if (tipsPorMostrar.length > 0) {
                setTipActual(tipsPorMostrar[Math.floor(Math.random() * tipsPorMostrar.length)]); // Actualizar el estado para mostrar el tip en el componente
            }
        };

        mostrarTip(); // Mostrar el primer tip
        const intervalId = setInterval(mostrarTip, 15000); // Mostrar un nuevo tip cada 15 segundos

        return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
    };

    return (
        <div>
            <h1>Recomendaciones</h1>
            <p className='testimonio'>{tipActual || 'Cargando...'}</p>
        </div>
    );
};

export default Tips;
