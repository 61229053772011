import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';


function CuerpoHistoria() {
  return (
    
    <div class="cuerpo fondo-naranja">


        <div class="display-flex">
            <div class="left left-60">
                <div class="año">

                    <h1>1952 - 1987</h1>
                    <img src="images/forma-historia-der.png" alt=""/>

                </div>
                <div class="fundacion">
                    <h2>Fundación y Primeros Años</h2>
                </div>
                <div class="parrafo1">
                    <p>
                        La Asociación de Bomberos Voluntarios de San Martín de los Andes fue fundada el 31 de enero de
                        1952. <br />
                        Entre los primeros miembros se encontraban figuras como Leotta, Creide, Mera, Del Hoyo y Vita.
                        <br /><br />
                        Durante los primeros años, la asociación enfrentó numerosos desafíos, operando con equipos muy
                        básicos y una infraestructura limitada. A pesar de estas dificultades, los bomberos voluntarios
                        demostraron un compromiso inquebrantable con la comunidad, organizando eventos y recaudaciones
                        para mejorar sus recursos.
                        <br /><br />
                        Este espíritu de dedicación y servicio sentó las bases para el crecimiento y desarrollo futuro
                        de la asociación.
                    </p>
                </div>
            </div>
            <div class="image-frame right right-40">
                <img src="images/historia/fotohistorica1.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica2.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica3.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica4.jpg" class="imagenn"/>
            </div>
        </div>



        <div class="display-flex display-flex-reverse">

            <div class="image-frame left left-40">
                <img src="images/historia/fotohistorica5.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica6.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica7.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica8.jpg" class="imagenn"/>
            </div>


            <div class="right right-60">
                <div class="año año-right desktop">

                    <img src="images/forma-historia-izq.png" alt=""/>
                    <h1>1975-1987</h1>

                </div>
                <div class="año año-right mobile">

                    <h1>1975-1987</h1>
                    <img src="images/forma-historia-der.png" alt=""/>

                </div>                
                <div class="fundacion">
                    <h2 class="h2-right">Reactivación y Crecimiento</h2>
                </div>
                <div class="parrafo1">
                    <p>
                        Después de un periodo de inactividad, la asociación fue reactivada en 1975 gracias a los esfuerzos de un grupo liderado por el señor Altamirano. En 1978, la asociación retomó sus funciones con renovado vigor. Durante este periodo, se adquirieron nuevos equipos, incluyendo el primer camión importante, un Ford 600. 
                        <br /><br />
                        La comunidad jugó un papel crucial en este renacimiento, apoyando a los bomberos a través de donaciones y participación en eventos. La creación del cuerpo auxiliar femenino en 1975, liderado por María Angélica Alberdi, marcó un hito importante, ampliando el alcance y la capacidad de respuesta de la asociación.
                    </p>
                </div>
            </div>

        </div>



        
        <div class="display-flex">
            <div class="left left-60">
                <div class="año">

                    <h1>1987-2020</h1>
                    <img src="images/forma-historia-der.png" alt=""/>

                </div>
                <div class="fundacion">
                    <h2>Modernización y Profesionalización</h2>
                </div>
                <div class="parrafo1">
                    <p>
                        A partir de 1987, bajo el liderazgo de figuras como Sergio González y Juan Belgrano, la asociación experimentó una transformación significativa. Se puso un fuerte énfasis en la capacitación continua y la adquisición de nuevas tecnologías. La implementación de la escuela de aspirantes en 1994 y la compra de equipos avanzados como tijeras hidráulicas y trajes estructurales mejoraron notablemente la capacidad operativa. 
                        <br /><br />
                        En 2010, se inauguró un nuevo destacamento, reflejando el crecimiento y la modernización de la asociación. Hoy en día, la Asociación de Bomberos Voluntarios de San Martín de los Andes es una institución profesional y respetada, preparada para enfrentar los desafíos del futuro. 
                    </p>
                </div>
            </div>
            <div class="image-frame right right-40">
                <img src="images/historia/fotohistorica9.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica10.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica11.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica12.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica13.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica14.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica15.jpg" class="imagenn"/>
                <img src="images/historia/fotohistorica16.jpg" class="imagenn"/>
            </div>
        </div>



        <div class="video-bomberos">
            <iframe width="1120" height="630" src="https://www.youtube.com/embed/Opwh-IN6RX4?si=REoDWr1dcOGf2iIR"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

    </div>



  );
}



function PaginaHistoria() {
  return (
    <div>
      <Header />
      <Encabezado />
      <CuerpoHistoria />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaHistoria;
