import React from "react";


function Accesibilidad() {

    return (
        <div>
            
            <div id="botonAccesibilidad" class="boton-accesibilidad">
                <span><img src="images/accesible-icon.png"/></span>
            </div>

            
            <div id="barraAccesibilidad" class="barra-accesibilidad">
                <ul  class="texto-dyslexic">
                    <li id="aumentarTexto">Aumentar Texto</li>
                    <li id="disminuirTexto">Disminuir Texto</li>
                    <li id="escalaGrises">Escala de Grises</li>
                    <li id="fondoOscuro">Fondo Oscuro</li>
                    <li id="fondoClaro">Fondo Claro</li>
                    <li id="alto-contraste">Alto Contraste</li>
                    <li id="fuenteDislexia">Apto para disléxicos</li>
                    <li id="restablecer">Restablecer</li>
                </ul>
            </div>

        </div>
    );
};



export default Accesibilidad;
