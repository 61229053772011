import React, { useContext } from 'react';
import { PaginaContext } from '../App'; // Importamos el contexto

function Creditos() {
    const { pagina, setPagina } = useContext(PaginaContext);

    return (
        <section class="creditos contenedor fondo-negro texto-montserrat">
        <div class="texto-blanco"> Copyright © 2024 - ASOCIACIÓN DE BOMBEROS VOLUNTARIOS  </div>
        <div class="bit"> 
            <a onClick={() => setPagina('equipo')} class="texto-blanco">
                DESARROLLADORES DE ESTE SITIO WEB
            </a>
            <a onClick={() => setPagina('equipo')} target="_blank" rel="noopener noreferrer">
                <img src="images/BIT-cotesma.png"/>
            </a>
        </div>
        </section>        
    );
};


export default Creditos;