import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';


function CuerpoContacto() {
  return (
<main class="cuerpo fondo-naranja texto-arial texto-blanco">
        <section class="contacto">
            <div class="mapa-central">
                <div class="googlemaps">
                    <h2>Cuartel Central</h2>
                    <div class="gmap_canvas"><iframe width="640" height="480" id="gmap_canvas" src="https://maps.google.com/maps?q=general+roca+1251+san+martin+de+los+andes&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                    </iframe>
  
                    </div>
                </div>
            </div>
            <div class="mapa-destacamento">
                <div class="googlemaps">
                    <h2>Destacamento "Chiche Sassaroli"</h2>
                    <div class="gmap_canvas"><iframe width="640" height="480" id="gmap_canvas" src="https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sdon+bosco+204+san+martin+de+los+andes!6i17" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                    </iframe>
                    </div>
                </div>
            </div>
        </section>
        <div class="contenedor-contacto">
            <section class="canales">
                <h2>Canales de contacto</h2>
                <ul>
                    <li><strong>E-mail Administración:</strong> <a href="mailto:bomberos@smandes.com.ar">bomberos@smandes.com.ar</a></li>
                    <li><strong>E-mail Cuerpo Activo:</strong> <a href="mailto:cuerpoactivosma@gmail.com">cuerpoactivosma@gmail.com</a></li>
                    <li><strong>Dirección (Cuartel Central):</strong> General Roca N° 1251 (8370) San Martín de los Andes - Neuquén</li>
                    <li><strong>Dirección (Destacamento):</strong> Don Bosco N° 204 (8370) San Martín de los Andes - Neuquén</li>
                    <li><strong>Teléfono:</strong> (02972) 422-816 (Cuartel Central) - (02972) 412-222 (Destacamento)</li>
                </ul>
                <div class="redes-sociales">

                        <a href="https://www.instagram.com/bomberosvoluntariossma/">
                            <img src="images/icon-ig.png" alt=""/>
                        </a>
                        <a href="https://www.youtube.com/@bomberosvoluntariossanmart7000">
                            <img src="images/icon-youtube.png?v=3" alt=""/>
                        </a>  
                        <a href="https://www.facebook.com/BomberosVoluntariosSMA">
                            <img src="images/icon-facebook.png" alt=""/>
                        </a>                                      

        
                </div>
            </section>
            <section class="form-contacto">
                <div id="form">
                    <h2>¡Dejanos tu comentario!</h2>
                    <form id="commentForm">
                        <ul>
                            <li>
                                <label for="name">Nombre:</label>
                                <input type="text" id="name" name="name" required/>
                            </li>
                            <li>
                                <label for="email">Correo Electrónico:</label>
                                <input type="email" id="email" name="email" required/>
                            </li>
                            <li>
                                <label for="subject">Asunto:</label>
                                <input type="text" id="subject" name="subject" required/>
                            </li>
                            <li>
                                <label for="comments">Comentarios:</label>
                                <textarea id="comments" name="comments" cols="70" rows="15" required></textarea>
                            </li>
                            <li>
                                <button type="submit">Enviar</button>
                            </li>
                        </ul>
                    </form>
                </div>

            </section>
        </div>
    </main>
  );
}



function PaginaContacto() {
  return (
    <div>
      <Header />
      <Encabezado />
      <CuerpoContacto />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaContacto;
