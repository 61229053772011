import Header from './Componentes/Header';
import Encabezado from './Componentes/Encabezado';
import Footer from './Componentes/Footer';
import Creditos from './Componentes/Creditos';
import BotonWS from './Componentes/BotonWS';


function CuerpoEstadisticas() {
  return (
    <div class="estadisticas">
      <hr/>
      <p>Estas estadísticas nos permite visualizar el servicio prestado por nuestros bomberos en la ciudad.</p>
      
      <div class="fila">
        <img src="https://bitcotesma.pythonanywhere.com/heridas/"/>
        <img src="images/cantidad_bomberos.png"/>
      </div>

      
      <img src="https://bitcotesma.pythonanywhere.com/incendios/"/>
      <img src="https://bitcotesma.pythonanywhere.com/servicios_dia/"/>
      <img src="https://bitcotesma.pythonanywhere.com/servicios_hora/"/>
      
      <p>Mapa de  calor de servicios prestados en nuestra ciudad</p>
      <iframe id="MapadeCalor"
         title="Mapa de Calor de Incidentes"
         width="100%"
         height="600"
         src="https://bitcotesma.pythonanywhere.com/static/mapa_de_calor_incidentes.html" >
     </iframe>
    </div>
  );
}




function PaginaEstadisticas() {
  return (
    <div>
      <Header />
      <Encabezado />
      <CuerpoEstadisticas />
      <Footer />
      <Creditos />
      <BotonWS />
    </div>
  );
}

export default PaginaEstadisticas;
