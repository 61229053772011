import React, { useState, useEffect } from 'react';

function CuerpoAct() {
  // Estado para almacenar los usuarios
  const [cuerpo, setCuerpo] = useState([]);

  // useEffect para hacer la llamada a la API al cargar el componente
  useEffect(() => {
    // Llamada a la API para obtener los usuarios
    fetch('https://bomberosvoluntariossma.org.ar/api/cuerpo-activo')
      .then(response => response.json())
      .then(data => {
        setCuerpo(data);  // Guardamos los usuarios en el estado
      })
      .catch(error => console.error('Error al obtener los usuarios:', error));
  }, []);


  // Renderizamos la lista de usuarios
  return (
      <table class="table table-bordered" id="cuartelActivo">
        <tbody>

          <tr>
            <td bgcolor="#D8D7D7">Legajo</td>
            <td bgcolor="#D8D7D7">Grado</td>
            <td bgcolor="#D8D7D7">Apellidos y Nombres</td>
          </tr>

          {cuerpo.map((fila, index) => (
            <tr>
              <td>{fila.legajo}</td>
              <td>{fila.grado}</td>
              <td>{fila.nombre}</td>
            </tr>            
          ))}

        </tbody>
    </table>
  );
}

export default CuerpoAct;