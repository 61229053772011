import React from "react"; 

function Footer() {
  return (
    <footer class="fondo-blanco">
        <div class="contenedor">

            <div class="redes">
                <a href="https://www.instagram.com/bomberosvoluntariossma/" target="_blank">
                    <img src="images/icon-ig.png"/>
                </a>
                <a href="https://www.youtube.com/@bomberosvoluntariossanmart7000" target="_blank">
                    <img src="images/icon-youtube.png?v=3"/>
                </a>  
                <a href="https://www.facebook.com/BomberosVoluntariosSMA" target="_blank">
                    <img src="images/icon-facebook.png"/>
                </a>                                      
            </div>

            <div class="direccion texto-negro texto-arial">
                <p>General Roca N° 1251</p>            
                <p>San Martín de los Andes, Neuquén</p>    
            </div>

            <div class="logo">
                <a href="index.html#home">
                    <img class="logo-footer" src="images/logo.png"/>
                </a>
            </div>
            
        </div>

        <script src="js/accesibilidad.js" async defer></script>
    </footer>
  );
}




export default Footer;
