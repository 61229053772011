import React, { useContext } from 'react';
import { PaginaContext } from '../App'; // Importamos el contexto


export default function Encabezado() {
    const { pagina, setPagina } = useContext(PaginaContext);
    let pagTitulo = pagina.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return (
  
        <section class="encabezado encabezado-contacto">
            <h2 class="texto-arial texto-blanco">{pagTitulo}</h2>
        </section>

    );
};